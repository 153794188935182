.legalDociFrameContainer {
    background-color: white;
    height: 75vh;
    width: 100%;
    justify-content: center;
    display: flex;

    iframe {
        width: 53em;
        max-width: 100%;
        height: 100%;
    }
}