.siteMapButton {
  background-color: #ffbb00;
  color: black;
  border-radius: 4px;
  justify-content: center;
  font-size: 1.1rem;
  align-items: center;
  padding: 10px 10px;
  margin-top: 10px;
  display: flex;
  height: 36px;
  width: fit-content;
  text-decoration: none;

  @media screen and (max-width: 600px) {
    display: none;
  }
}
.pageBorder {
  display: flex;
  justify-content: space-evenly;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.siteMapOtherInner {
  background-color: white;
  border: 1px solid rgb(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0px;
  width: auto;
  border-top: none;

  @media screen and (max-width: 850px) {
    max-width: 100%;
  }

  .titleRow {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 1.5rem;
    line-height: 1.1;

    .titleText {
      width: fit-content;
    }

    .titleIcon {
      margin-right: 10px;
      margin-bottom: 12px;
    }
  }

  .siteMapOtherLink {
    padding: 10px 10px 10px 20px;

    &:hover {
      background-color: #fafafa;
      color: black !important;
    }


    .itemRow {
      display: flex;
      flex-direction: row;
    }

    .descriptionText {
      line-height: 1.1;
      margin-top: 5px;
      opacity: 0.9;
      font-size: 0.9rem;

      p {
        margin-bottom: 0px;
      }
    }

    .arrowIcon {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 850px) {
        justify-content: right;
        flex-direction: row;
      }
    }

    .pageRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      font-size: 16px;

      h3 {
        font-size: 1.2rem;
        line-height: 1.1;
        ;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
  }
}

.siteMapInner {
  background-color: white;
  border: 1px solid rgb(0, 0, 0, 0.3);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0px;
  width: auto;
  border-top: none;

  @media screen and (max-width: 850px) {
    max-width: 100%;
    justify-content: center;
    display: block;

    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  .itemRow {
    display: flex;
    flex-direction: row;
  }

  .titleRow {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.1;

    .titleText {
      width: fit-content;
      margin-bottom: 8px;
    }

    .titleIcon {
      margin-right: 10px;
      margin-bottom: 12px;
    }
  }

  .arrowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pageRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 600;

    h3 {
      font-size: 1.2rem;
      line-height: 1.1;
      ;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  .pageDescription {
    line-height: 1.1;
    margin-top: 5px;
    opacity: 0.9;
    font-size: 0.9rem;
  }

  .linkRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
      margin-bottom: 0px;
    }
  }

  .toolTipSiteMap {
    display: inline-block;
    position: relative;

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }


  .testServerLink {
    background-color: hwb(191 21% 25%);
    color: white;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 2.5em;
    font-size: 14px;
    width: 7em;

    @media screen and (max-width: 425px) {
      margin-top: 10px;
      width: 100%;
    }
  }

  .prodRCLink {
    background-color: hwb(124 17% 40%);
    color: white;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 2.5em;
    font-size: 14px;
    width: 7em;

    @media screen and (max-width: 425px) {
      margin-top: 10px;
      width: 100%;
    }

  }

  .prodLiveLink {
    background-color: hwb(353 22% 17%);
    color: white;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 2.5em;
    font-size: 14px;
    width: 7em;

    @media screen and (max-width: 425px) {
      margin-top: 10px;
      width: 100%;
    }
  }

  .localLink {
    background-color: hwb(33 11% 11%);
    color: white;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 2.5em;
    width: 7em;

    font-size: 14px;
    width: 100px;

    @media screen and (max-width: 425px) {
      margin-top: 10px;
      width: 100%;
    }
  }

  .nonClickableLink {
    background-color: hwb(0 57% 43%);
    color: white;
    cursor: default;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 2.5em;
    font-size: 14px;
    width: 7em;

    &:active {
      pointer-events: none;
    }

    @media screen and (max-width: 425px) {
      margin-top: 10px;
      width: 100%;
    }
  }

}

.siteMapRowCont{
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  @media screen and (max-width: 849px) {
    display: block;
    width: 100%;
  }
}

.siteMapColumnCont{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 33%;
  @media screen and (max-width: 849px) {
    display: block;
    width: 100%;
  }
}

.siteMapColumn {
  margin-bottom: 25px;
  .siteMapOtherInner {
    &:first-child {
      border-top: 1px solid rgb(0, 0, 0, 0.3);
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }
  
    &:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }

  .siteMapInner {
    &:first-child {
      border-top: 1px solid rgb(0, 0, 0, 0.3);
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
    }
  
    &:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}

