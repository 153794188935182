@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Canadiana;
  src: url('CanadianaText-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}
