@import "Colours";

.skipCont {
    width: 100%;
}

.skipSubCont {
    background-color: $maple-dark-blue;
    padding: 5px;
    display: inline-block !important;
    border-radius: 5px;
    margin-left: 5px;
    border: 1px solid #1b1c1d;

}

.loadSkipLinkCont {
    display: inline-block !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.skipLink {
    color: white;
    font-size: 15pt;
    background-color: transparent;
    border: 0;
}

.skipLinkCont {
    top: 0;
    position: fixed;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -1000%);
    transition: transform 0.3s;
    width: 100%;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    text-align:center;
}

.skipSubCont:hover {
    background-color: $button-primary-active !important;
}


.skipLinkCont:focus-within {
    transform: translate(-50%,-10%);
}

.skipLinkCont:focus {
    transform: translate(50%);
}

@media screen and (max-width: 600px) {
    #cardFooterSkipLink {
        display: none !important;
    }
}