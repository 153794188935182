$maple-red: #E4002B;
$maple-light-blue: #2568C4;
$maple-dark-blue: #033088;
$maple-dark-blue-bright: #252774;
$maple-medium-blue: #8FB9E3;
$maple-light-bg: #FFFFFF;

$state-success: #28A745;
$state-warning: #ffd300;
$state-danger: #db3242;

$link: #033088;
$link-hover: #001B76;

$button-primary-active: #001B76;

$footer-main: $maple-dark-blue;
$footer-disclaimer: #3B3D42;
