@import 'Colours';

#nav {
  --link-y-padding: 0.5em;
  --link-x-padding: 0.5em;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--nav-bar-bg-color);
  z-index: 10;

  a {
    text-decoration: none;
  }
}

.hideOnDesktop {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
}

.navContent {
  margin: 0 auto;
  font-size: 1.1rem;
  font-weight: 600;
  border-bottom: 1px solid #cbcbcb;
}

#Links a,
#Links button,
#Hamburger {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  font-weight: inherit;
  
  &:hover {
    color: inherit;
  }
}

#Links {
  z-index: 10;
  a, button {
    padding: var(--link-y-padding) var(--link-x-padding);
    box-sizing: content-box;
    position: relative;
  }
  #siteMapButton {
    background-color: #ffbb00;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
    width: fit-content;
    text-decoration: none;
    margin-left: 5px;
    padding-inline: calc(var(--link-x-padding) - 5px);
    &:hover {
      background-color: hsl(44, 100%, 65%);
      color: black;
    }
  }
}

.logo {
  aspect-ratio: 1/1;
  width: 40px;
  display: block;
  margin-left: 14px;
  position: relative;
}

.text {
  color: black;
  font-weight: 600;
  font-size: 0.8em;
  margin-bottom: 0.2em;
}

.row {
  height: 60px;
}

@media only screen and (min-width: 865px) {
  #nav {
    transition: background-color 0.25s;
    &.atTop {
      background-color: rgb($maple-light-bg, 0.31);

      .navContent {
        border-bottom: none;
      }
  
      .text,
      & ~ .navContent .text {
        height: 0;
      }
    }
  }

  .navContent {
    padding: 0 1rem;
    position: relative;
  }

  .corners {
    display: none;
  }

  .logo {
    margin-left: 0;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 600;
    color: black;
  }

  #Links > div {
    display: flex;
    align-items: center;
    gap: 1em;
    height: 100%;

    #siteMapButton {
      position: absolute;
      left: 80px;
      font-size: 1.1rem;
      height: auto;
      line-height: normal;
      padding: 0.5rem var(--link-x-padding);
    }
    .dropdown {
      position: relative;
      display: inline-block;
    }
    
    & > {
      a, button {
        &:not(#PurchaseBtn):after{
          content: '';
          position: absolute;
          background-color: $maple-dark-blue;
          height: 0;
          width: 20%;
          left: 40%;
          display: block;
          bottom: 3px;
          z-index: -1;
          transition: width 0.2s, left 0.2s;
        }
        &:not(#PurchaseBtn):hover::after {
          height: 3px;
          width: 80%;
          left: 10%;
        }
      }
    }

    .dropContent {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      position: absolute;
      min-width: fit-content;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      border-radius: 4px;
      overflow: hidden;
      background-color: white;
      transition: opacity 0.15s, transform 0.15s, visibility 0.15s;
      &.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
  
      a {
        opacity: 0.8;
        text-decoration: none;
        display: block;
        padding: 0.2em 0.7em;
        white-space: nowrap;
        &:first-child {
          padding-top: 0.3em;
        }
        &:last-child {
          padding-bottom: 0.3em;
        }
        &:hover, &:focus-visible {
          background-color: #f1f1f1;
          outline: none;
        }
      }
    }

    #PurchaseBtn {
      background-color: $maple-dark-blue;
      color: var(--nav-bar-bg-color);
      border-radius: 100px;
      line-height: 1;
      padding: 0.7em 1.6em;
      height: initial;
      transition: background-color 0.1s;
      &:hover {
        background-color: $button-primary-active;
      }
    }
  }

  .text {
    margin-top: -0.2rem;
    overflow: hidden;
    transition: height 0.25s;
  }
}

@media only screen and (min-width: 865px) and (max-width: 1100px) {
  html[lang="fr"] {
    #Links {
      & > div {
        gap: 0;
      }
      a, button {
        font-size: 0.8em;
      }
    }
  }
}

@media only screen and (max-width: 864.9px) {
  #nav {
    --link-y-padding: 0.3em;
    --link-x-padding: 1em;
  }

  .navContent {
    padding: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .logo {
    align-self: center;
  }

  .corners {
    width: 1.25em;
    height: 1.25em;
    margin: 0 0.5em;
    font-size: 1.5em;
    &:first-child {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left: 0;
    }
  }

  #Hamburger {
    padding: 0;
    opacity: .8;
  }

  .text {
    text-align: center;
    visibility: hidden;
  }
  .text.show {
    visibility: visible;
  }

  #Links {
    flex-basis: 100%;
    display: grid;
    transition: grid-template-rows 0.3s, visibility 0.3s;
    grid-template-rows: 0fr;
    visibility: hidden;
    background-color: var(--nav-bar-bg-color);
    & > div {
      overflow: hidden;
    }

    a, button {
      display: block;
      width: 100%;
      text-align: left;
      transition: background-color 0.25s;
      box-sizing: border-box;
      &:hover, &:focus-visible {
        outline: none;
        background-color: hsl(0, 0%, 90%);
      }
    }

    #DropBtn {
      display: none;
    }
  }


  #nav ~ .navContent .text {
    display: block;
  }

  .navContent.expanded #Links {
    grid-template-rows: 1fr;
    border-bottom: 1px solid #cbcbcb;
    visibility: visible;
  }
}
