@import 'Colours';

.footer-top {
  background-color: $footer-main;
  width: 100%;
  padding: 3em 0 2em 0 !important;

  .col-md-2,
  .col-md-4 {
    margin-bottom: 20px;
  }

  .footer-title {
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    font-size: 1.375rem;
    margin-bottom: 14px;
  }

  .footer-store-buttons{
    width: 135px;
    @media (max-width: 760px) {
      width: 135px;
    }
    @media (max-width: 880px) {
      width: 110px;
    }
  }

  /*links*/
  a {
    font-size: 1.125rem;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none !important;
    font-weight: inherit;
    &:hover {
      color: white;
    }

    &:not(.dropdown-item) {
      float: left;
      clear: left;
      margin-bottom: 1rem;
    }

    &.download-app {
      margin-bottom: 5px !important;
    }
  }

  & > span {
    color: white;
  }
}

.footer-bottom {
  background-color: $footer-disclaimer;

  .footerSubHeader {
      display: block;
      font-weight: 500;
      font-size: 0.8125rem !important;
      padding-top: 1rem !important;

      @media screen and (max-width: 600px) {
        padding-bottom: 0px;
      }
    }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 300;
    font-size: 0.6875rem !important;
    text-align: center;
    margin: 0;
    white-space: pre-line;
    padding-top: 0px !important;
  }
}

.footer-card-label {
  font-size: 1.25rem !important;
}

#mag-card-img {
  filter: drop-shadow(-4px 4px 4px rgba(255, 255, 255, 0.2));
}

#dropbtn {
  background-color: transparent;
  border: solid white 2px;
  width: 120%;
  text-align: left;
  font-size: 1.25rem;
  padding-left: 0;
  padding-right: 0;
}

#dropbtn::after {
  display: none;
}

.dropdown-language {
  margin-top: 2em;
  .fal {
    padding: 25px;
  }
}

.dropdown-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  color: white;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-menu {
  background-color: $maple-dark-blue !important;
  border: solid white 2px !important;
  width: 120%;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  color: white !important;
  align-items: center;
  text-decoration: none !important;
  padding-left: 50px !important;
}

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:target {
  background-color: white !important;
  color: black !important;
}
